<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="tep_contratos_nacionales_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Tep Contratos Nacionales
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="no_contrato" class="col-md-5"># Contrato</label>

                    <input
                      type="number"
                      id="no_contrato"
                      v-model="filtros.no_contrato"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresa()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="fecha_ini" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_ini"
                      v-model="filtros.fecha_ini"
                      class="col-md-7"
                      @input="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_fin" class="col-md-5">Fecha Final</label>
                    <input
                      type="date"
                      id="fecha_fin"
                      v-model="filtros.fecha_fin"
                      class="col-md-7"
                      @input="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="estado1" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="no_contrato1"
                              v-model="form.no_contrato"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="no_contrato1"
                              ># Contrato</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="no_otrosi1"
                              v-model="form.no_otrosi"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="no_otrosi1"
                              >Otro Si</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa_id"
                              v-model="form.empresa_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa_id"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="funcionario_id"
                              v-model="form.funcionario_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="funcionario_id"
                              >Funcionario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_contrato"
                              v-model="form.tipo_contrato"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_contrato"
                              >Tipo Contrato</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="objeto_contrato"
                              v-model="form.objeto_contrato"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="objeto_contrato"
                              >Objeto Contrato</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ini1"
                              v-model="form.fecha_ini"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_ini1"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_fin1"
                              v-model="form.fecha_fin"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_fin1"
                              >Fecha Final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="valor"
                              v-model="form.valor"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="valor"
                              >Valor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="diferencia_valor"
                              v-model="form.diferencia_valor"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="diferencia_valor"
                              >Diferencia Valor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="valor_efectuado"
                              v-model="form.valor_efectuado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="valor_efectuado"
                              >Valor Efectuado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="pernocte"
                              v-model="form.pernocte"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="pernocte"
                              >Pernocte</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="telefono"
                              v-model="form.telefono"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="telefono"
                              >Teléfono</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="correo"
                              v-model="form.correo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="correo"
                              >Correo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="combustible"
                              v-model="form.combustible"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="combustible"
                              >Combustible</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="peajes"
                              v-model="form.peajes"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="peajes"
                              >Peajes</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="vigencia_poliza"
                              v-model="form.vigencia_poliza"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="vigencia_poliza"
                              >Vigencia póliza</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observaciones"
                              v-model="form.observaciones"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observaciones"
                              >Observaciones</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tif.contratos.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "TepContratoNacionalExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      form: {
        no_contrato: true,
        no_otrosi: true,
        empresa_id: true,
        funcionario_id: true,
        tipo_contrato: true,
        objeto_contrato: true,
        fecha_ini: true,
        fecha_fin: true,
        valor: true,
        diferencia_valor: true,
        valor_efectuado: true,
        pernocte: true,
        nombre: true,
        telefono: true,
        correo: true,
        combustible: true,
        peajes: true,
        vigencia_poliza: true,
        observaciones: true,
        estado: true,
      },
      filtros: {
        no_contrato: null,
        fecha_ini: null,
        fecha_fin: null,
        empresa_id: null,
        empresa: null,
        estado: null,
      },
      listasForms: {
        empresas: [],
        estados: [],
      },
    };
  },

  methods: {
    async getEmpresa() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectEmpresa() {
      this.filtros.empresa = {};
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa = this.empresa;
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_ini);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/contratosNacionales/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresa();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
